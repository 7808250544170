import './HeaderComponent.css';

import { InboxOutlined, SettingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Input, Menu, Modal, Popconfirm, Space, Spin, Table, Upload } from 'antd';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import Lambda from 'aws-sdk/clients/lambda';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { graphqlListAll } from '../amplify-apis/graphQLCalls';
import { getS3, listS3, uploadS3 } from '../amplify-apis/userFiles';
import awsExports from '../aws-exports';
import { AuthContext } from '../Contexts';
import {
    createAssignment,
    createGrade,
    createStudent,
    createTA,
    deleteTA,
    updateAssignment,
    updateStudent,
    updateTA,
} from '../graphql/mutations';
import { listAssignments, listStudents, listTAs } from '../graphql/queries';

// import { deleteAssignment, deleteGrade, deleteStudent } from '../graphql/mutations';
// import { listGrades } from '../graphql/queries';

const ASSIGNMENT = '2';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

function HeaderComponent() {
    const [isModalVisible, setIsModalVisible] = useState(null);
    const [path, setpath] = useState(window.location.href);
    const cognitoPayload = useContext(AuthContext).cognitoPayload;

    function showModal(visibleModal) {
        setIsModalVisible(visibleModal);
    }

    function modalClose() {
        setIsModalVisible(null);
    }

    function setPath() {
        setTimeout(() => {
            setpath(window.location.href)
        }, 100);
    }

    function getLinkClass(linkPath) {
        if (path.indexOf(linkPath) !== -1) {
            return "nav-button nav-menu nav-active"
        }
        return "nav-button nav-menu"
    }

    function NavButtons() {
        const guestMenu = (
            <>
                <Button id="explorations-btn" type="link" className={getLinkClass('/view#assignment=1')}>
                    <Link to="/view#assignment=1" onClick={setPath}>Assignment 1</Link>
                </Button>
                <Button id="explorations-btn" type="link" className={getLinkClass('/view#assignment=2')}>
                    <Link to="/view#assignment=2" onClick={setPath}>Assignment 2</Link>
                </Button>
            </>
        );
        const menu = (
            <>
            <Button id="explorations-btn" type="link" className={getLinkClass('/grade#assignment=1')}>
                <Link to="/grade#assignment=1" onClick={setPath}>Grade Assignment 1</Link>
            </Button>
            <Button id="explorations-btn" type="link" className={getLinkClass('/grade#assignment=2')}>
                <Link to="/grade#assignment=2" onClick={setPath}>Grade Assignment 2</Link>
            </Button>
            </>
    );
        return (
            <nav>
                <h1 id="mobius-evo" className="nav-button">
                    {/* <Link to="/">Mobius Gallery (v {packageJson.version})</Link> */}
                    <Link to="/">Mobius Gallery</Link>
                </h1>
                {guestMenu}
                {cognitoPayload && menu}
            </nav>
        );
    }

    function SettingsDropdown() {
        if (!cognitoPayload["cognito:groups"] || cognitoPayload["cognito:groups"].indexOf("admin") === -1) {
            return null;
        }
        // async function clearAllData() {
        //     await API.graphql(graphqlOperation(listGrades, {}))
        //         .then((queriedResults) => {
        //             const assignmentList = queriedResults.data.listGrades.items;
        //             assignmentList.forEach((a) => {
        //                 API.graphql(
        //                     graphqlOperation(deleteGrade, {
        //                         input: {
        //                             id: a.id,
        //                         },
        //                     })
        //                 ).catch((ex) => console.log(ex));;
        //             });
        //         }
        //     ).catch((ex) => console.log(ex));
        //     await API.graphql(graphqlOperation(listAssignments, {}))
        //         .then((queriedResults) => {
        //             const assignmentList = queriedResults.data.listAssignments.items;
        //             assignmentList.forEach((a) => {
        //                 API.graphql(
        //                     graphqlOperation(deleteAssignment, {
        //                         input: {
        //                             id: a.id,
        //                         },
        //                     })
        //                 ).catch((ex) => console.log(ex));;
        //             });
        //         }
        //     ).catch((ex) => console.log(ex));
        //     await API.graphql(graphqlOperation(listStudents, {}))
        //         .then((queriedResults) => {
        //             const assignmentList = queriedResults.data.listStudents.items;
        //             assignmentList.forEach((a) => {
        //                 API.graphql(
        //                     graphqlOperation(deleteStudent, {
        //                         input: {
        //                             id: a.id,
        //                         },
        //                     })
        //                 ).catch((ex) => console.log(ex));;
        //             });
        //         }
        //     ).catch((ex) => console.log(ex));
        // }
        // function updateTestGrade() {
        //     API.graphql(graphqlOperation(listGrades, {limit: 300}))
        //         .then((queriedResults) => {
        //             const gradeList = queriedResults.data.listGrades.items;
        //             console.log('gradeList length',gradeList.length)
        //             gradeList.forEach((grade) => {
        //                 const dataGrade = {"style": 0, "para": 0, "diff": 0, "adj": 0, "total": 0}
        //                 for (const gradeType of ["style", "para", "diff"]) {
        //                     for (let i = 0; i < 4; i++ ) {
        //                         const rand = Math.floor(Math.random() * 3) - 1;
        //                         dataGrade[gradeType] += rand
        //                     }
        //                     if (dataGrade[gradeType] > 3) { dataGrade[gradeType] = 3; }
        //                     if (dataGrade[gradeType] < -3) { dataGrade[gradeType] = -3; }
        //                 }
        //                 dataGrade.total = dataGrade.style + dataGrade.para + dataGrade.diff
        //                 const newGrade = {
        //                     id: grade.id,
        //                     grades: JSON.stringify(dataGrade),
        //                     comment: grade.comment,
        //                 };
    
        //                 API.graphql(
        //                     graphqlOperation(updateGrade, {
        //                         input: newGrade,
        //                     })
        //                 );
        //             });
        //         })
        //         .catch((ex) => console.log(ex));
        // }
        async function exportData() {
            const studentList = {}
            await API.graphql(graphqlOperation(listStudents, {
                limit: 1000,
            }))
            .then(queriedResults => {
                const results = queriedResults.data.listStudents.items
                for (const student of results) {
                    studentList[student.id] = student
                }
            })
            .catch((ex) => console.log(ex));

            API.graphql(graphqlOperation(listAssignments, {
                limit: 1000,
            }))
                .then((queriedResults) => {
                    const exportData = [];
                    const assignmentList = queriedResults.data.listAssignments.items;
                    exportData.push(`id, name, email, student number, ,` +
                    `AS1 coding style, AS1 parametrisation, AS1 differentiation, AS1 adjustment, AS1 total, ,` + 
                    `AS2 coding style, AS2 parametrisation, AS2 differentiation, AS2 adjustment, AS2 total`)
                    assignmentList.forEach((assignment) => {
                        try {
                            const grade = JSON.parse(assignment.grade.grades);
                            studentList[assignment.student.id][`as${assignment.assignment_type}_style`] = grade.style
                            studentList[assignment.student.id][`as${assignment.assignment_type}_para`] = grade.para
                            studentList[assignment.student.id][`as${assignment.assignment_type}_diff`] = grade.diff
                            studentList[assignment.student.id][`as${assignment.assignment_type}_adj`] = grade.adj
                            studentList[assignment.student.id][`as${assignment.assignment_type}_total`] = grade.total
                        } catch (ex) {
                            studentList[assignment.student.id][`as${assignment.assignment_type}_style`] = 'error'
                            studentList[assignment.student.id][`as${assignment.assignment_type}_para`] = 'error'
                            studentList[assignment.student.id][`as${assignment.assignment_type}_diff`] = 'error'
                            studentList[assignment.student.id][`as${assignment.assignment_type}_adj`] = 'error'
                            studentList[assignment.student.id][`as${assignment.assignment_type}_total`] = 'error'
                        }
                    });

                    Object.values(studentList).forEach((student) => {
                        const assignmentData = [];
                        assignmentData.push(student.id);
                        assignmentData.push(student.name);
                        assignmentData.push(student.email);
                        assignmentData.push(student.student_number);

                        assignmentData.push(' ');
                        assignmentData.push(student.as1_style);
                        assignmentData.push(student.as1_para);
                        assignmentData.push(student.as1_diff);
                        assignmentData.push(student.as1_adj);
                        assignmentData.push(student.as1_total);
                        assignmentData.push(' ');

                        assignmentData.push(student.as2_style);
                        assignmentData.push(student.as2_para);
                        assignmentData.push(student.as2_diff);
                        assignmentData.push(student.as2_adj);
                        assignmentData.push(student.as2_total);
                        exportData.push(assignmentData.join(','))
                    })
                    const exportString = "data:text/csv;charset=utf-8," + exportData.join('\n')
                    const encodedUri = encodeURI(exportString);
                    const link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "export_grade.csv");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch((ex) => console.log(ex));
        }
        // async function exportData() {
        //     // create new assignments
        //     const existingStudents = await graphqlListAll(listStudents, 'listStudents');
        //     let s = ''
        //     for (const stu of existingStudents) {
        //         try {
        //             getS3(stu.id + '_2.mob', () => {}, () => {s += stu.id + ', ' + stu.name + ', ' + stu.email + '\n'}).catch((ex) => {})
        //         } catch(ex) {

        //         }
        //     }
        //     setTimeout(() => {
        //         console.log(s)
        //     }, 20000);
        // }
    
        const menu = (
            <Menu>
                <Menu.Item onClick={() => showModal("Import Data")} key="importData">
                    Import Data
                </Menu.Item>
                <Menu.Item onClick={() => showModal("Update TA Group")} key="updateGroup">
                    Update TA Group
                </Menu.Item>
                <Menu.Item onClick={recapturePics} key="recapturePics">
                    Retake Screenshot
                </Menu.Item>
                {/* <Menu.Item onClick={clearAllData} key="clearAllData">
                    Clear All Data
                </Menu.Item> */}
                {/* <Menu.Item onClick={updateTestGrade} key="updateTestGrade">
                    update test grade
                </Menu.Item> */}
                <Menu.Item onClick={exportData} key="exportData">
                    Export Data
                </Menu.Item>
            </Menu>
        );
        return (
            <Dropdown overlay={menu} trigger={["click"]}>
                <Button>
                    <SettingOutlined />
                </Button>
            </Dropdown>
        );
    }

    function AccButton() {
        return (
            cognitoPayload? (
                <Space>
                    <Button id="user-btn" type="link" className="nav-button">
                        <Link to="/user">Hi, {cognitoPayload.nickname}</Link>
                    </Button>
                    <SettingsDropdown></SettingsDropdown>
                    <Button id="signout" onClick={() => Auth.signOut().then(() => window.location.reload(false))}>
                        Sign Out
                    </Button>
                </Space>
            ) : (
                <Space>
                    <Button id="login" onClick={() => window.location.href = '/signin'}>
                        Sign In
                    </Button>
                </Space>
            )
        );
    }

    function parseCSV(data_string) {
        const splitted_data = data_string.trim().split("\n");
        const parsed_data = [];
        const headers = splitted_data[0].split(",").map((s) => s.trim());

        for (let line_idx = 1; line_idx < splitted_data.length; line_idx++) {
            const line_data = splitted_data[line_idx].split(",").map((s) => s.trim());
            const parsed_line = {};
            for (let att_idx = 0; att_idx < headers.length; att_idx++) {
                if (att_idx >= line_data.length) {
                    break;
                }
                if (!headers[att_idx] || !line_data[att_idx]) {
                    continue;
                }
                parsed_line[headers[att_idx]] = line_data[att_idx];
            }
            parsed_data.push(parsed_line);
        }
        return parsed_data;
    }
    async function addCSVData(data_string) {
        const data = parseCSV(data_string);
        const promises = [
            graphqlListAll(listStudents, 'listStudents'),
            graphqlListAll(listAssignments, 'listAssignments')
        ]
        const existingStudents = await promises[0];
        const existingAssignments = await promises[1];
        for (const student of data) {
            let exStudent = null;
            for (const existingStudent of existingStudents) {
                if (student['User ID'] === existingStudent.id) {
                    exStudent = existingStudent;
                    break;
                }
            }
            const studentParam = {
                id: student['Student Number'],
                name: student['Name'],
                student_number: student['Student Number'],
                email: student['Email'],
                group: student['Group'],
                other_info: JSON.stringify(student),
            };
            if (!exStudent) {
                await API.graphql(graphqlOperation(createStudent, { input: studentParam })).catch((ex) =>
                    console.log(ex)
                );
            } else {
                let check = false;
                for (const i in studentParam) {
                    if (studentParam[i] !== exStudent[i]) {
                        check = true;
                    }
                }
                if (!check) {
                    await API.graphql(graphqlOperation(updateStudent, { input: studentParam })).catch((ex) =>
                        console.log(ex)
                    );
                }
            }
            const assignmentID = student['Student Number'] + "_" + ASSIGNMENT;
            let exAssignment = null;
            for (const existingAssignment of existingAssignments) {
                if (assignmentID === existingAssignment.id) {
                    exAssignment = existingAssignment;
                    break;
                }
            }
            const assignmentParam = {
                id: student['Student Number'] + "_" + ASSIGNMENT,
                file_url: `https://${awsExports.aws_user_files_s3_bucket}.s3.${awsExports.aws_user_files_s3_bucket_region}.amazonaws.com/public/${student['Student Number']}_${ASSIGNMENT}.mob`,
                img_url: `https://${awsExports.aws_user_files_s3_bucket}.s3.${awsExports.aws_user_files_s3_bucket_region}.amazonaws.com/public/${student['Student Number']}_${ASSIGNMENT}.mob.jpg`,
                assignment_type: ASSIGNMENT,
                gradeID: student['Student Number'] + "_" + ASSIGNMENT,
                studentID: student['Student Number'],
            };
            if (!exAssignment) {
                const gradeParam = {
                    id: student['Student Number'] + "_" + ASSIGNMENT,
                    grades: '{"style": null, "para": null, "diff": null, "adj": 0, "total": null}',
                    comment: "",
                };
                await API.graphql(graphqlOperation(createGrade, { input: gradeParam })).catch((ex) => console.log(ex));
                API.graphql(graphqlOperation(createAssignment, { input: assignmentParam })).catch((ex) => console.log(ex));
            } else if (exAssignment.file_url !== assignmentParam.file_url) {
                API.graphql(graphqlOperation(updateAssignment, { input: assignmentParam })).catch((ex) => console.log(ex));
            }
        }
    }
    async function recapturePics() {

        listS3(async files => {
            console.log('files:', files)
            const promiseList = [];
            for (const f of files) {
                if (f.key === '' || f.key.endsWith('.jpg')) { continue; }
                const p = new Promise(resolve =>{
                    Auth.currentCredentials().then((credentials) => {
                        const lambda = new Lambda({
                            region: awsExports.aws_appsync_region,
                            credentials: Auth.essentialCredentials(credentials),
                        });
                        lambda.invoke(
                            {
                                FunctionName: "galleryScreenshot-dev",
                                Payload: JSON.stringify({
                                    file: `public/${f.key}`,
                                    width: 600,
                                    height: 400,
                                }),
                            },
                            (err, _) => {
                                if (err) {
                                    console.log("screenshot failed: " + f.key, err);
                                } else {
                                    console.log("screenshot success: " + f.key);
                                }
                                resolve(null)
                            }
                        );
                    });
                })
                promiseList.push(p)
            }
            await Promise.all(promiseList)
            console.log('finish~~~')
        }, () => {})
    }

    function ImportData() {
        let lambda;
        Auth.currentCredentials().then((credentials) => {
            lambda = new Lambda({
                region: awsExports.aws_appsync_region,
                credentials: Auth.essentialCredentials(credentials),
            });
        });
        const props = {
            multiple: true,
            customRequest({ action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials }) {
                if (file.name.endsWith(".mob")) {
                    const filename = file.name.replace('.mob', '') + '_' + ASSIGNMENT +'.mob'
                    uploadS3(
                        filename,
                        file,
                        true,
                        () => {
                                lambda.invoke(
                                    {
                                        FunctionName: "galleryScreenshot-dev",
                                        Payload: JSON.stringify({
                                            file: `public/${filename}`,
                                            width: 600,
                                            height: 400,
                                        }),
                                    },
                                    (err, _) => {
                                        if (err) {
                                            console.log("screenshot failed", err);
                                            onError();
                                        } else {
                                            console.log("Upload success");
                                            onSuccess();
                                        }
                                    }
                                );
                        },
                        onError,
                        onProgress
                    );
                } else if (file.name.endsWith(".csv")) {
                    file.text().then((text) => {
                        addCSVData(text)
                            .then(onSuccess)
                            .catch((ex) => {
                                console.log("error:", ex);
                                onError();
                            });
                    });
                    // addCSVData(file.text());
                } else {
                    console.log("      !!", file.name);
                    onError();
                }
            },
        };
        return (
            <Modal title={isModalVisible} visible={isModalVisible} onOk={modalClose} onCancel={modalClose}>
                <Upload.Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file(s) to this area to upload</p>
                </Upload.Dragger>
            </Modal>
        );
    }

    function UpdateTAList() {
        const cols = [
            {
                title: "email",
                dataIndex: "email",
                width: "60%",
                editable: true,
            },
            {
                title: "group",
                dataIndex: "group",
                editable: true,
            },
            {
                title: "operation",
                dataIndex: "operation",
                render: (_, record) =>
                    dataState.dataSource.length >= 1 ? (
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                            <button type="button" className="link-button">
                                Delete
                            </button>
                        </Popconfirm>
                    ) : null,
            },
        ];
        const [dataState, setdataState] = useState({
            dataSource: [],
            count: 0,
        });
        const [isLoading, setisLoading] = useState(false);

        function refreshTAList() {
            setisLoading(true);
            API.graphql(graphqlOperation(listTAs, {}))
                .then((queriedResults) => {
                    const results = queriedResults.data.listTAs.items.map((item) => {
                        return {
                            key: item.email,
                            email: item.email,
                            group: item.group,
                        };
                    });
                    setdataState({
                        dataSource: results,
                        count: results.length,
                    });
                    setisLoading(false);
                })
                .catch((ex) => {
                    console.log(ex);
                    setisLoading(false);
                });
        }
        useEffect(refreshTAList, []);

        const handleDelete = (key) => {
            const newData = [...dataState.dataSource].filter((item) => item.key !== key);
            setdataState({
                dataSource: newData,
                count: newData.length,
            });
        };
        const handleAdd = () => {
            const { count, dataSource } = dataState;
            const newData = {
                key: new Date().toISOString(),
                email: "TA email",
                group: "TA group",
            };
            setdataState({
                dataSource: [...dataSource, newData],
                count: count + 1,
            });
        };
        const handleSave = (row) => {
            const newData = [...dataState.dataSource];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
            setdataState({
                dataSource: newData,
                count: newData.length,
            });
        };

        const { dataSource } = dataState;
        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        const columns = cols.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                }),
            };
        });
        function modalUpdate() {
            setisLoading(true);
            API.graphql(graphqlOperation(listTAs, {}))
                .then(async (queriedResults) => {
                    const promiseList = [];
                    const existingItems = queriedResults.data.listTAs.items.map((item) => {
                        return {
                            email: item.email,
                            group: item.group,
                        };
                    });
                    for (const eItem of existingItems) {
                        let check = false;
                        for (const nItem of dataState.dataSource) {
                            if (eItem.email === nItem.email) {
                                check = true;
                                if (eItem.group === nItem.group) {
                                    break;
                                }
                                promiseList.push(
                                    API.graphql(
                                        graphqlOperation(updateTA, {
                                            input: {
                                                id: eItem.email,
                                                email: eItem.email,
                                                username: "",
                                                group: nItem.group,
                                            },
                                        })
                                    )
                                );
                                break;
                            }
                        }
                        if (!check) {
                            promiseList.push(
                                API.graphql(
                                    graphqlOperation(deleteTA, {
                                        input: {
                                            id: eItem.email,
                                        },
                                    })
                                )
                            );
                        }
                    }
                    for (const nItem of dataState.dataSource) {
                        let check = false;
                        for (const eItem of existingItems) {
                            if (eItem.email === nItem.email) {
                                check = true;
                                break;
                            }
                        }
                        if (!check) {
                            promiseList.push(
                                API.graphql(
                                    graphqlOperation(createTA, {
                                        input: {
                                            id: nItem.email,
                                            email: nItem.email,
                                            username: "",
                                            group: nItem.group,
                                        },
                                    })
                                )
                            );
                        }
                    }
                    await Promise.all(promiseList);
                    setisLoading(false);
                    setIsModalVisible(null);
                })
                .catch((ex) => {
                    console.log(ex);
                    setisLoading(false);
                    setIsModalVisible(null);
                });
        }
        function updateFromCSV() {
            const fileInp = document.getElementById('file-selector')
            if (fileInp.files.length === 0) { return; }
            const { count, dataSource } = dataState;
            for (const f of fileInp.files) {
                f.text().then(text => {
                    let newDataState = {
                        dataSource: dataSource,
                        count: count,
                    }
                    const csvData = parseCSV(text)
                    for (const taData of csvData) {
                        if (!taData.group) { 
                            taData.group = 'Group ADJ'
                        }
                        const newData = {
                            key: taData.email,
                            email: taData.email,
                            group: taData.group,
                        };
                        newDataState = {
                            dataSource: [...newDataState.dataSource, newData],
                            count: newDataState.count + 1,
                        };
                    }
                    fileInp.value = null
                    setdataState(newDataState);
                })
            }
        }
        return (
            <Modal title={isModalVisible} visible={isModalVisible} onOk={modalUpdate} onCancel={modalClose}>
                <Spin spinning={isLoading}>
                    <Space direction="horizontal">
                        <Button
                            onClick={handleAdd}
                            style={{
                                marginBottom: 16,
                            }}
                        >
                            Add a row
                        </Button>
                        <Button 
                            type="primary"
                            icon={<UploadOutlined />}
                            style={{
                                marginBottom: 16,
                            }}
                            onClick={()=> document.getElementById('file-selector').click()}
                        >Add from CSV</Button>
                    </Space>

                    <Table
                        components={components}
                        rowClassName={() => "editable-row"}
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                    />
                    <input type="file" id="file-selector" onChange={updateFromCSV}/>
                </Spin>
            </Modal>
        );
    }

    function PageModal() {
        if (isModalVisible === "Import Data") {
            return ImportData();
        } else if (isModalVisible === "Update TA Group") {
            return UpdateTAList();
        }
        return (
            <Modal title={isModalVisible} visible={isModalVisible} onOk={modalClose} onCancel={modalClose}>
                <p>.....</p>
            </Modal>
        );
    }

    return (
        <header>
            <NavButtons />
            <AccButton />
            <PageModal />
        </header>
    );
}

export default HeaderComponent;
