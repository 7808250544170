import './Landing.css';
import '@aws-amplify/ui-react/styles.css';

import { Authenticator } from '@aws-amplify/ui-react';
import { Col, Row } from 'antd';
import Amplify, { Auth } from 'aws-amplify';
import { useContext } from 'react';

import awsExports from '../../aws-exports';
import { AuthContext } from '../../Contexts';

// import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp } from "@aws-amplify/ui-react";
function Landing() {
    function NotAuthenticated() {
        const setCognitoPayload = useContext(AuthContext).setCognitoPayload;
        async function authUser() {
            try {
                let currSession = await Auth.currentSession();
                setCognitoPayload(currSession.getIdToken().payload);
                Amplify.configure({
                    ...awsExports,
                    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
                });
                window.location.href = '/grade#assignment=2'
            } catch (err) {
                if (err !== "No current user") {
                    // no current user on page load
                    alert(err);
                }
            }
        }

        // const components = {
        //     SignIn: {
        //       Header() {
        //         const { tokens } = useTheme();
          
        //         return (
        //           <Heading
        //             padding={`${tokens.space.large} 0 0 ${tokens.space.xxxl}`}
        //             level={5}
        //             fontWeight="bold"
        //           >
        //             Sign in
        //           </Heading>
        //         );
        //       },
        //     }
        // }
        return (
            <Authenticator variation="modal" signUpAttributes={['nickname']}
            //   components={components}
            >
                {({ signOut, user }) => {
                    authUser();
                    return <></>;
                }}
            </Authenticator>
        );
    }
    return (
        <div id="landing-container">
            <Row className="landing-row">
                <Col md={24}>
                    <br/>
                    <br/>
                    <br/>
                    {!useContext(AuthContext).cognitoPayload ? <NotAuthenticated /> : null}
                </Col>
            </Row>
        </div>
    );
}

export default Landing;
