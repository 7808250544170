import { API, graphqlOperation } from 'aws-amplify';

export async function graphqlListAll(query, queryName, options = {}, useIAM = false) {
    let results = []
    let nextToken = null;
    function manageResult(queryResults) {
        nextToken = queryResults.data[queryName].nextToken
        results = results.concat(queryResults.data[queryName].items);
    }
    function onerror(error) {
        console.log(error)
    }
    const q = graphqlOperation(query, options);
    if (useIAM){
        q.authMode = 'AWS_IAM';
    }
    await API.graphql(q).then(manageResult).catch(onerror);
    while (nextToken) {
        const q = graphqlOperation(query, {
            ...options,
            'nextToken': nextToken
        });
        if (useIAM){
            q.authMode = 'AWS_IAM';
        }
        await API.graphql(q).then(manageResult).catch(onerror);
    }
    return results;
}