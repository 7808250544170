import './ViewProjects.css';

import { Card, Col, Pagination, Row, Space, Spin, Tabs } from 'antd';
import * as QueryString from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { graphqlListAll } from '../../amplify-apis/graphQLCalls';
import { AuthContext } from '../../Contexts';
import { assignmentsByType } from '../../graphql/custom-queries';

const { TabPane } = Tabs;

const MOBIUS_URL = 'https://mobius-08.design-automation.net/flowchart?file='
const DEFAULT_IMG = 'https://mobiusgallerystorage102106-dev.s3.us-east-1.amazonaws.com/public/error.jpg'

// TODO: reduce number of database call

const refreshProjects = async (setprojectData, setisLoading) => {
    setisLoading(true)
    const assignment = QueryString.parse(window.location.hash).assignment;
    const projList = await graphqlListAll(assignmentsByType, 'assignmentsByType', {
        limit: 300,
        assignment_type: assignment,
        filter: null,
        items: {},
        nextToken: null,
    }, true);
    try {
        const data = {'all': []};
        projList.forEach((proj) => {
            if (assignment && proj.assignment_type !== assignment) { return; }
            data['all'].push(proj);
            if (!proj.student.group) {
                return;
            }
            proj.student.group = proj.student.group.replace('Group ', '')
            if (!data[proj.student.group]) {
                data[proj.student.group] = [];
            }
            data[proj.student.group].push(proj);
        });
        for (const group in data) {
            data[group] = data[group].sort((a, b) => {
                if (a.student.name < b.student.name) {
                    return -1
                }
                return 1;
            });
        }
        setprojectData(data);
    } catch (ex) {
        console.warn(ex);
    }
    setisLoading(false);
};
function getCardSpan() {
    let newCardSpan = Math.ceil((24 * 470) / window.innerWidth);
    if (newCardSpan > 24) {
        newCardSpan = 24;
    } else if (24 % newCardSpan !== 0) {
        newCardSpan = 24 / Math.floor(24 / newCardSpan);
    }
    return newCardSpan;
}

function DisplayProjects({ projectData}) {
    const [cardSpan, setcardSpan] = useState(getCardSpan());
    const [pagination, setpagination] = useState({});
    const [pageSize, setpageSize] = useState({});
    const { cognitoPayload } = useContext(AuthContext);

    function handleResize() {
        const newCardSpan = getCardSpan();
        if (cardSpan !== newCardSpan) {
            setcardSpan(newCardSpan);
        }
    }

    useEffect(() => {
        if (window.__resizeEventListenerFunc__) {
            window.removeEventListener("resize", window.__resizeEventListenerFunc__)
        }
        window.addEventListener("resize", handleResize);
        window.__resizeEventListenerFunc__ = handleResize
    });

    let groups = [];
    if (projectData) {
        groups = Object.keys(projectData);
        groups = groups.sort();
        const all = groups.splice(groups.length - 1, 1)
        groups.unshift(all[0])
    }


    function GradeTab(tab) {
        const groupData = projectData[tab];
        if (!pagination[tab]) {
            pagination[tab] = 1
        }
        if (!pageSize[tab]) {
            pageSize[tab] = 100
        }
        let showData = groupData.slice(
            pageSize[tab] * (pagination[tab] - 1),
            pageSize[tab] * pagination[tab]
        );
        function changePage(npage, npageSize) {
            if (pagination[tab] !== npage) {
                pagination[tab] = npage;
                setpagination({...pagination});
            } else {
                pagination[tab] = 1
                pageSize[tab] = npageSize
                setpageSize({...pageSize});
                setpagination({...pagination});
            }
            document.getElementById('main-section').scrollTo({top: 0, behavior: 'smooth'})
        }
        return (
            <TabPane tab={tab === 'all'? 'ALL' : 'G' + tab} key={tab}>
                <Space direction="vertical" size='large'>
                    <Pagination
                        current={pagination[tab]}
                        pageSize={pageSize[tab]} //default size of page
                        onChange={changePage}
                        total={groupData.length} //total number of card data available
                    />
                    <Row gutter={[16, 16]}>
                        {showData.map((data, i) => {
                            let studentName = ''
                            if (cognitoPayload) {
                                studentName = data.student.name
                            } else if (i < 9) {
                                studentName = 'Student 00' + (i + 1)
                            } else if (i < 99) {
                                studentName = 'Student 0' + (i + 1)
                            } else {
                                studentName = 'Student ' + (i + 1)
                            }
                            const displayName = tab === 'all'? `${studentName} - G${data.student.group}` : studentName
                            return (
                                <Col span={cardSpan} key={data.id}>
                                    <Card title={displayName} className="project-card">
                                        <a href={MOBIUS_URL + '_' + Buffer.from(data.file_url, 'utf8').toString('base64')} key="img" target='mobius_view'>
                                            <img className="card-img" alt=''
                                            src={data.img_url} 
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src=DEFAULT_IMG;
                                            }} />
                                        </a>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>
                    <Pagination
                        current={pagination[tab]}
                        pageSize={pageSize[tab]} //default size of page
                        onChange={changePage}
                        total={groupData.length} //total number of card data available
                    />
                </Space>
            </TabPane>
        );
    }
    return ( groups.length > 0 &&
        <Tabs defaultActiveKey="all" size="large">
            {groups.map((group) =>
                // GradeTab(projectData, group, cardSpan, [page, setpage])
                GradeTab(group)
            )}
        </Tabs>
    );
}

function ViewProjects() {
    const [isLoading, setisLoading] = useState(true);
    const [projectData, setprojectData] = useState(null);
    const location = useLocation();

  
  
    useEffect(() => {
        refreshProjects(setprojectData, setisLoading);
    }, [location]);

    return (
        <Space direction="vertical" size="large" style={{ width: "inherit" }}>
            <Spin spinning={isLoading}>
                <DisplayProjects projectData={ projectData }/>
                <br />
                <br />
            </Spin>
        </Space>
    );
}

export default ViewProjects;
