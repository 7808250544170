import './GradeProjects.css';

import { Line, Violin } from '@ant-design/charts';
import { Button, Card, Col, Collapse, Input, Pagination, Row, Select, Space, Spin, Table, Tabs } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import * as QueryString from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { graphqlListAll } from '../../amplify-apis/graphQLCalls';
import { AuthContext } from '../../Contexts';
import { updateGrade } from '../../graphql/mutations';
import { assignmentsByType, listTAs } from '../../graphql/queries';

const { TabPane } = Tabs;

const MOBIUS_URL = "https://mobius-08.design-automation.net/flowchart?file=";
const DEFAULT_IMG = 'https://mobiusgallerystorage102106-dev.s3.us-east-1.amazonaws.com/public/error.jpg'


const sortProj = (a, b) => {
    function compareName(a, b) {
        if (a.student.name < b.student.name) {
            return -1
        } else if (a.student.name > b.student.name) {
            return 1
        }
        return 0;
    }
    if ((a.grade.grades.total === null && b.grade.grades.total === null) || a.grade.grades.total === b.grade.grades.total) {
        return compareName(a, b)
    }
    if (a.grade.grades.total === null) {
        return -1
    }
    if (b.grade.grades.total === null) {
        return 1
    }
    return b.grade.grades.total - a.grade.grades.total;
}

const parseGrade = (gradeString) => {
    const parsedGrade = JSON.parse(gradeString);
    if (!parsedGrade.adj) {
        parsedGrade['adj'] = 0;
    }
    return parsedGrade;
}


// TODO: reduce number of database call

const refreshProjects = async (setprojectData, setoriginalGradeData, setauthGroup, setisLoading, cognitoPayload) => {
    setisLoading(true);
    const assignment = QueryString.parse(window.location.hash).assignment;
    const projList = await graphqlListAll(assignmentsByType, 'assignmentsByType', {
        limit: 300,
        assignment_type: assignment,
        filter: null,
        items: {},
        nextToken: null,
    });

    // const queryAssignments = API.graphql(graphqlOperation(assignmentsByType, {})).catch((error) => console.log(error));
    const TAList = await graphqlListAll(listTAs, 'listTAs');
    try {
        const data = {'all': []};
        const originalGradeData = {};
        const groups = []
        projList.forEach((proj) => {
            if (assignment && proj.assignment_type !== assignment) {
                return;
            }
            if (!proj.student.group) {
                return;
            }
            proj.student.group = proj.student.group.replace('Group ', '')
            if (!data[proj.student.group]) {
                data[proj.student.group] = [];
                groups.push(proj.student.group)
            }
            if (proj.grade && proj.grade.grades) {
                proj.grade.grades = parseGrade(proj.grade.grades);
                originalGradeData[proj.grade.id] = JSON.parse(JSON.stringify(proj.grade))
            }
            data[proj.student.group].push(proj);
            data['all'].push(proj);
        });
        for (const group of groups) {
            data[group] = data[group].sort(sortProj);
        }
        data['all'] = data['all'].sort(sortProj);
        setprojectData(data);
        setoriginalGradeData(originalGradeData)
    } catch (ex) {
        console.warn(ex);
    }
    try {
        if (cognitoPayload["cognito:groups"] && cognitoPayload["cognito:groups"][0] === "admin") {
            setauthGroup('adj');
        }
        for (const ta of TAList) {
            if (cognitoPayload.email === ta.email) {
                setauthGroup(ta.group.replace('Group ', ''));
                break;
            }
        }
    } catch (ex) {
        console.warn(ex);
    }
    setisLoading(false);
};
function getCardSpan() {
    let newCardSpan = Math.ceil((24 * 470) / window.innerWidth);
    if (newCardSpan > 24) {
        newCardSpan = 24;
    } else if (24 % newCardSpan !== 0) {
        newCardSpan = 24 / Math.floor(24 / newCardSpan);
    }
    return newCardSpan;
}

function DisplayProjects({ projectDataState, originalGradeDataState, authGroup, cognitoPayload, setisLoading }) {
    const { projectData, setprojectData } = projectDataState;
    const { originalGradeData, setoriginalGradeData } = originalGradeDataState;
    const [cardSpan, setcardSpan] = useState(getCardSpan());
    const [change, setchange] = useState(false)
    const [pagination, setpagination] = useState({});
    const [pageSize, setpageSize] = useState({});
    function handleResize() {
        const newCardSpan = getCardSpan();
        if (cardSpan !== newCardSpan) {
            setcardSpan(newCardSpan);
        }
    }

    function sortProjects() {
        for (const g in projectData) {
            projectData[g] = projectData[g].sort(sortProj);
        }
        setprojectData(projectData)
    }

    useEffect(() => {
        if (window.__resizeEventListenerFunc__) {
            window.removeEventListener("resize", window.__resizeEventListenerFunc__)
        }
        window.addEventListener("resize", handleResize);
        window.__resizeEventListenerFunc__ = handleResize
    });

    let groups = [];
    if (projectData) {
        groups = Object.keys(projectData).sort();
        const all = groups.splice(groups.length - 1, 1)
        groups.unshift(all[0])
    }
    const gradeTypes = ["style", "para", "diff"];
    const gradeTypesTitle = { style: "Coding Style", para: "Parametrisation", diff: "Differentiation" };

    function CardComponent({ data, tab }) {
        // if (authGroup && (authGroup === tab || authGroup === 'adj')) {
        //     return <GradeCardComponent data={data} tab={tab} key="gradeComp" />;
        // }
        return <ViewCardComponent data={data} key="gradeComp" />;
    }

    // DISABLED GRADING
    // function GradeCardComponent({ data, tab }) {
    //     const grade = data.grade;
    //     let dataGrade = grade.grades;

    //     function updateAssignmentGrade(gradetype, value) {
    //         dataGrade[gradetype] = value;
    //         let total = 0;
    //         for (const i of gradeTypes) {
    //             if (dataGrade[i] === null) {
    //                 total = null;
    //                 break;
    //             }
    //             total += dataGrade[i];
    //         }
    //         if (total !== null) {
    //             total += dataGrade.adj;
    //         }
    //         dataGrade.total = total;
    //         document.getElementById(`${tab}_${data.id}_total`).value = total !== null ? "total: " + total : "total "
    //         const promptCls = document.getElementById(`${tab}_${data.id}_prompt`)
    //         if (promptCls.className.indexOf('hiddenPrompt') !== -1) {
    //             promptCls.className = promptCls.className.replace(' hiddenPrompt','').trim()
    //         }
    //     }

    //     function changeAssignmentComment(event, value) {
    //         grade.comment = event.target.value;
    //         const promptCls = document.getElementById(`${tab}_${data.id}_prompt`)
    //         if (promptCls.className.indexOf('hiddenPrompt') !== -1) {
    //             promptCls.className = promptCls.className.replace(' hiddenPrompt','').trim()
    //         }
    //     }

    //     async function applyChange() {
    //         setisLoading(true)
    //         const oGrade = originalGradeData[grade.id];
    //         oGrade.comment = grade.comment;
    //         oGrade.grades = JSON.parse(JSON.stringify(dataGrade))
    //         grade.updated = false;
    //         setoriginalGradeData(originalGradeData)
    //         const newGrade = {
    //             id: grade.id,
    //             grades: JSON.stringify(dataGrade),
    //             comment: grade.comment,
    //         };
    //         await API.graphql(graphqlOperation(updateGrade, { input: newGrade })).catch((ex) => console.log(ex));
    //         const promptCls = document.getElementById(`${tab}_${data.id}_prompt`)
    //         if (promptCls.className.indexOf('hiddenPrompt') === -1) {
    //             promptCls.className = promptCls.className.trim() + ' hiddenPrompt'
    //         }
    //         sortProjects()
    //         setisLoading(false)
    //         setchange(!change)
    //     }

    //     function cancelChange() {
    //         setisLoading(true)
    //         grade.comment = originalGradeData[grade.id].comment
    //         grade.grades = JSON.parse(JSON.stringify(originalGradeData[grade.id].grades))
    //         grade.updated = false;
    //         const promptCls = document.getElementById(`${tab}_${data.id}_prompt`)
    //         if (promptCls.className.indexOf('hiddenPrompt') === -1) {
    //             promptCls.className = promptCls.className.trim() + ' hiddenPrompt'
    //         }
    //         setprojectData(projectData)
    //         setisLoading(false)
    //         setchange(!change)
    //     }

    //     function gradeSelect(gradetype) {
    //         if (authGroup && authGroup === 'adj') {
    //             return (
    //                 <Col span={8} key={gradetype}>
    //                     <Input
    //                         className="grade-select"
    //                         value={dataGrade[gradetype] !== null ? `${gradetype}: ` + dataGrade[gradetype] : `${gradetype} `}
    //                         disabled
    //                     />
    //                 </Col>
    //             );
    //         }
    //         return (
    //             <Col span={8} key={gradetype}>
    //                 <Select
    //                     className="grade-select"
    //                     placeholder={gradetype}
    //                     title={gradeTypesTitle[gradetype]}
    //                     defaultValue={dataGrade[gradetype]}
    //                     onChange={(value) => updateAssignmentGrade(gradetype, value)}
    //                 >
    //                     <Select.Option value={-3}>{gradetype}: -3</Select.Option>
    //                     <Select.Option value={-2}>{gradetype}: -2</Select.Option>
    //                     <Select.Option value={-1}>{gradetype}: -1</Select.Option>
    //                     <Select.Option value={0}>{gradetype}: 0</Select.Option>
    //                     <Select.Option value={1}>{gradetype}: 1</Select.Option>
    //                     <Select.Option value={2}>{gradetype}: 2</Select.Option>
    //                     <Select.Option value={3}>{gradetype}: 3</Select.Option>
    //                 </Select>
    //             </Col>
    //         );
    //     }
    //     function gradeAdjustment() {
    //         if (authGroup && authGroup === 'adj') {
    //             return (
    //                 <Col span={8} key="adj">
    //                     <Select
    //                         className="grade-select"
    //                         placeholder='adj'
    //                         title='Adjustment'
    //                         defaultValue={dataGrade.adj}
    //                         onChange={(value) => updateAssignmentGrade('adj', value)}
    //                     >
    //                         <Select.Option value={-3}>adj: -3</Select.Option>
    //                         <Select.Option value={-2}>adj: -2</Select.Option>
    //                         <Select.Option value={-1}>adj: -1</Select.Option>
    //                         <Select.Option value={0}>adj: 0</Select.Option>
    //                         <Select.Option value={1}>adj: 1</Select.Option>
    //                         <Select.Option value={2}>adj: 2</Select.Option>
    //                         <Select.Option value={3}>adj: 3</Select.Option>
    //                     </Select>
    //                 </Col>
    //             );
    //         }
    //         return (
    //             <Col span={8} key="adj">
    //                 <Input
    //                     className="grade-select"
    //                     value={`adj: ` + dataGrade.adj}
    //                     disabled
    //                 />
    //             </Col>
    //         );
    //     }
    //     function GradeApplyButtons() {
    //         return (<Space id={`${tab}_${data.id}_prompt`} className='hiddenPrompt'>
    //             <Button type="primary" onClick={applyChange}>Apply</Button>
    //             <Button onClick={cancelChange}>Cancel</Button>
    //         </Space>)
    //     }
    //     return (
    //         <Space className="grade-space" direction="vertical" size="large">
    //             <Row gutter={8}>
    //                 {gradeTypes.map((gradetype) => gradeSelect(gradetype))}
    //             </Row>
    //             <Row gutter={8}>
    //                 {gradeAdjustment()}
    //                 <Col span={8} key="_">
    //                 </Col>
    //                 <Col span={8} key="total">
    //                     <Input
    //                         className="grade-select"
    //                         id={`${tab}_${data.id}_total`}
    //                         value={dataGrade.total !== null ? "total: " + dataGrade.total : "total "}
    //                         disabled
    //                     />
    //                 </Col>
    //             </Row>
    //             <Input.TextArea
    //                 rows={4}
    //                 key="comment"
    //                 className="comment-textarea"
    //                 defaultValue={grade.comment}
    //                 onChange={changeAssignmentComment}
    //             />
    //             <GradeApplyButtons />
    //         </Space>
    //     );
    // }

    function ViewCardComponent({ data }) {
        const grade = data.grade;
        let dataGrade = grade.grades;
        return (
            <Space className="grade-space" direction="vertical" size="large">
                <Row gutter={8}>
                    {gradeTypes.map((gradetype) => (
                        <Col span={8} key={gradetype}>
                            <Input
                                className="grade-select"
                                value={dataGrade[gradetype] !== null ? `${gradetype}: ` + dataGrade[gradetype] : `${gradetype} `}
                                disabled
                            />
                        </Col>
                    ))}
                </Row>
                <Row gutter={8}>
                    <Col span={8} key="adjustment">
                        <Input
                            className="grade-select"
                            value={dataGrade.adj !== null ? "adj: " + dataGrade.adj : "adj "}
                            disabled
                        />
                    </Col>
                    <Col span={8} key="_">
                    </Col>
                    <Col span={8} key="total">
                        <Input
                            className="grade-select"
                            value={dataGrade.total !== null ? "total: " + dataGrade.total : "total "}
                            disabled
                        />
                    </Col>
                </Row>
                <Input.TextArea
                    rows={4}
                    key="comment"
                    className="comment-textarea"
                    defaultValue={grade.comment}
                    disabled
                />
            </Space>
        );
    }

    function ScoreLineGraph({ data }) {
        const scoreData = {};
        gradeTypes.forEach((x) => (scoreData[x] = []));
        scoreData.total = [];
        for (let i = -12; i <= 12; i++) {
            if (i >= -3 && i <= 3) {
                gradeTypes.forEach((gradeType) => {
                    scoreData[gradeType].push({
                        category: gradeType,
                        score: String(i),
                        count: 0,
                    });
                });
            }
            scoreData.total.push({
                category: "total",
                score: String(i),
                count: 0,
            });
        }
        data.forEach((proj) => {
            for (const gradeType in proj.grade.grades) {
                if (gradeType === 'adj') { continue; }
                scoreData[gradeType][proj.grade.grades[gradeType]
                    + (scoreData[gradeType].length - 1) / 2].count += 1;
            }
        });
        let graphData = [];

        while (scoreData.total[0].count === 0) {
            scoreData.total.shift()
        }
        while (scoreData.total[scoreData.total.length - 1].count === 0) {
            scoreData.total.pop()
        }

        for (const gradeType of gradeTypes) {
            graphData = graphData.concat(scoreData[gradeType]);
        }
        graphData = graphData.concat(scoreData["total"]);
        // for (const gradeType in scoreData) {
        //     graphData = graphData.concat(scoreData[gradeType]);
        // }

        graphData = graphData.sort((a, b) => parseInt(a.score) - parseInt(b.score));
        const scoreGraph = {
            data: graphData,
            xField: "score",
            yField: "count",
            seriesField: "category",
        };
        // const totalGraph = {
        //     data: scoreData['total'],
        //     xField: 'score',
        //     yField: 'count',
        //     seriesField: 'category',
        //   };
        return (
            <>
                <Line {...scoreGraph} />
                {/* <Line {...totalGraph} /> */}
            </>
        );
    }

    function ScoreBoxPlot({ data }) {
        let graphData = [];
        data.forEach((proj) => {
            for (const gradeType in proj.grade.grades) {
                if (gradeType === 'adj') { continue; }
                graphData.push({
                    type: gradeType,
                    score: proj.grade.grades[gradeType],
                });
            }
            graphData.push({
                type: "total",
                score: proj.grade.grades["total"],
            });
        });

        const config = {
            // width: 400,
            height: 500,
            data: graphData,
            xField: "type",
            yField: "score",
        };
        return <Violin {...config} />;

    }

    function StatTable({ data }) {
        const scoreData = {};
        gradeTypes.forEach((x) => (scoreData[x] = { sum: 0 }));
        scoreData.total = { sum: 0 };
        data.forEach((proj) => {
            for (const gradeType in proj.grade.grades) {
                if (gradeType === 'adj') { continue; }
                if (proj.grade.grades[gradeType] || proj.grade.grades[gradeType] === 0) {
                    scoreData[gradeType].sum += proj.grade.grades[gradeType];
                }
            }
        });
        const averageData = {
            key: "avg",
            type: 'Average'
        }
        for (const x in scoreData) {
            scoreData[x].avg = scoreData[x].sum / data.length
            averageData[x] = scoreData[x].avg;
        }
        const dataSource = [averageData]

        const columns = [
            {
                title: "",
                dataIndex: "type",
                key: "type",
            },
            ...gradeTypes.map(x => {
                return {
                    title: gradeTypesTitle[x],
                    dataIndex: x,
                    key: x,
                }
            }),
            {
                title: "Total",
                dataIndex: "total",
                key: "total",
            }
        ];

        return <Table dataSource={dataSource} columns={columns} pagination={{ position: ['none', 'none'] }} />;
    }

    function GradeGraphs({ data }) {
        return (
            <Space className="grade-space" direction="vertical" size={70}>
                <StatTable data={data}></StatTable>
                <ScoreLineGraph data={data}></ScoreLineGraph>
                <ScoreBoxPlot data={data}></ScoreBoxPlot>
            </Space>
        );
    }

    function GradeTab(projectData, tab) {
        const groupData = projectData[tab];
        if (!pagination[tab]) {
            pagination[tab] = 1
        }
        if (!pageSize[tab]) {
            pageSize[tab] = 100
        }
        let showData = groupData.slice(
            pageSize[tab] * (pagination[tab] - 1),
            pageSize[tab] * pagination[tab]
        );
        function changePage(npage, npageSize) {
            if (pagination[tab] !== npage) {
                pagination[tab] = npage;
                setpagination({ ...pagination });
            } else {
                pagination[tab] = 1
                pageSize[tab] = npageSize
                setpageSize({ ...pageSize });
                setpagination({ ...pagination });
            }
            document.getElementById('main-section').scrollTo({ top: 0, behavior: 'smooth' })
        }
        return (
            <TabPane tab={tab === "all" ? "ALL" : "G" + tab} key={tab}>
                <Collapse defaultActiveKey={["projects"]}>
                    <Collapse.Panel header="Projects" key="projects">
                        <Space direction="vertical" size='large'>
                            <Pagination
                                current={pagination[tab]}
                                pageSize={pageSize[tab]} //default size of page
                                onChange={changePage}
                                total={groupData.length} //total number of card data available
                            />
                            <Row gutter={[16, 16]}>
                                {showData.map((data) => {
                                    return (
                                        <Col span={cardSpan} key={data.id}>
                                            <Card title={tab==='all' ? data.student.name + ' - G' + data.student.group : data.student.name} className="project-card">
                                                <Space className="grade-space" direction="vertical" size="large">
                                                    <a href={MOBIUS_URL + data.file_url} key="img" target="mobius_view">
                                                        <img className="card-img" alt='' src={data.img_url} 
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; 
                                                            currentTarget.src=DEFAULT_IMG;
                                                        }} />
                                                    </a>
                                                    <CardComponent data={data} tab={tab} key="gradeComp" />
                                                </Space>
                                            </Card>
                                        </Col>
                                    );
                                })}
                            </Row>
                            <Pagination
                                current={pagination[tab]}
                                pageSize={pageSize[tab]} //default size of page
                                onChange={changePage}
                                total={groupData.length} //total number of card data available
                            />
                        </Space>
                    </Collapse.Panel>
                    <Collapse.Panel header="Grading Graphs" key="graphs">
                        <GradeGraphs data={groupData}></GradeGraphs>
                    </Collapse.Panel>
                </Collapse>
            </TabPane>
        );
    }
    return (authGroup && (
        <Tabs defaultActiveKey={authGroup} size="large">
            {groups.map((group) => GradeTab(projectData, group))}
        </Tabs>
    ));
}

function GradeProjects() {
    const { cognitoPayload } = useContext(AuthContext);
    const [isLoading, setisLoading] = useState(true);
    const [originalGradeData, setoriginalGradeData] = useState(null);
    const [projectData, setprojectData] = useState(null);
    const [authGroup, setauthGroup] = useState(null);
    const location = useLocation();

    // const sortProps = {
    //     sorter: true,
    //     sortDirections: ["ascend", "descend"],
    // };

    useEffect(() => {
        refreshProjects(setprojectData, setoriginalGradeData, setauthGroup, setisLoading, cognitoPayload);
    }, [cognitoPayload, location]);

    return (
        <Space direction="vertical" size="large" style={{ width: "inherit" }}>
            <Spin spinning={isLoading}>
                <DisplayProjects
                    projectDataState={{ projectData, setprojectData }}
                    originalGradeDataState={{ originalGradeData, setoriginalGradeData }}
                    authGroup={authGroup}
                    cognitoPayload={cognitoPayload}
                    setisLoading={setisLoading}
                />
                <br />
                <br />
            </Spin>
        </Space>
    );
}

export default GradeProjects;
