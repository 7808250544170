import { Storage } from 'aws-amplify';

export async function getS3(s3Key, resolved, rejected) {
    try {
        const s3Blob = await Storage.get(s3Key, { level: "public", download: true });
        const s3Text = await s3Blob.Body.text();
        resolved(s3Text);
    } catch (err) {
        console.log(err)
        rejected();
    }
}

export async function getS3Url(s3Key, resolved, rejected) {
    try {
        const s3url = await Storage.get(s3Key, { level: "public"});
        resolved(s3url)
    } catch (err) {
        rejected();
    }
}

export async function uploadS3(s3Key, object, publicRead, onSuccess, onError, onProgress) {
    const s3config = {
        level: "public",
        progressCallback(progress) {
            onProgress((progress.loaded * 100) / progress.total);
        },
    };
    if (publicRead) { s3config.acl = "public-read"; }
    try {
        await Storage.put(s3Key, object, s3config);
        onSuccess();
    } catch (err) {
        console.log('error', err)
        onError();
    }
}

export async function listS3(resolved, rejected) {
    try {
        const file_list = await Storage.list('', { level: "public"});
        resolved(file_list)
    } catch (err) {
        rejected();
    }
}
