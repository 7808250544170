export const assignmentsByType = /* GraphQL */ `
  query AssignmentsByType(
    $assignment_type: String
    $sortDirection: ModelSortDirection
    $filter: ModelAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assignmentsByType(
      assignment_type: $assignment_type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        file_url
        img_url
        assignment_type
        studentID
        createdAt
        updatedAt
        student {
          id
          name
          student_number
          email
          group
          other_info
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
