/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGrade = /* GraphQL */ `
  query GetGrade($id: ID!) {
    getGrade(id: $id) {
      id
      grades
      comment
      createdAt
      updatedAt
    }
  }
`;
export const listGrades = /* GraphQL */ `
  query ListGrades(
    $filter: ModelGradeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGrades(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        grades
        comment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTA = /* GraphQL */ `
  query GetTA($id: ID!) {
    getTA(id: $id) {
      id
      email
      username
      group
      createdAt
      updatedAt
    }
  }
`;
export const listTAs = /* GraphQL */ `
  query ListTAs($filter: ModelTAFilterInput, $limit: Int, $nextToken: String) {
    listTAs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        username
        group
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      name
      student_number
      email
      group
      other_info
      createdAt
      updatedAt
    }
  }
`;
export const listStudents = /* GraphQL */ `
  query ListStudents(
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        student_number
        email
        group
        other_info
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAssignment = /* GraphQL */ `
  query GetAssignment($id: ID!) {
    getAssignment(id: $id) {
      id
      file_url
      img_url
      assignment_type
      studentID
      gradeID
      grade {
        id
        grades
        comment
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      student {
        id
        name
        student_number
        email
        group
        other_info
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAssignments = /* GraphQL */ `
  query ListAssignments(
    $filter: ModelAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        file_url
        img_url
        assignment_type
        studentID
        gradeID
        grade {
          id
          grades
          comment
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        student {
          id
          name
          student_number
          email
          group
          other_info
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const assignmentsByType = /* GraphQL */ `
  query AssignmentsByType(
    $assignment_type: String
    $sortDirection: ModelSortDirection
    $filter: ModelAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assignmentsByType(
      assignment_type: $assignment_type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        file_url
        img_url
        assignment_type
        studentID
        gradeID
        grade {
          id
          grades
          comment
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        student {
          id
          name
          student_number
          email
          group
          other_info
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
