/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      id
      name
      student_number
      email
      group
      other_info
      createdAt
      updatedAt
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
      id
      name
      student_number
      email
      group
      other_info
      createdAt
      updatedAt
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent(
    $input: DeleteStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    deleteStudent(input: $input, condition: $condition) {
      id
      name
      student_number
      email
      group
      other_info
      createdAt
      updatedAt
    }
  }
`;
export const createAssignment = /* GraphQL */ `
  mutation CreateAssignment(
    $input: CreateAssignmentInput!
    $condition: ModelAssignmentConditionInput
  ) {
    createAssignment(input: $input, condition: $condition) {
      id
      file_url
      img_url
      assignment_type
      studentID
      gradeID
      grade {
        id
        grades
        comment
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      student {
        id
        name
        student_number
        email
        group
        other_info
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAssignment = /* GraphQL */ `
  mutation UpdateAssignment(
    $input: UpdateAssignmentInput!
    $condition: ModelAssignmentConditionInput
  ) {
    updateAssignment(input: $input, condition: $condition) {
      id
      file_url
      img_url
      assignment_type
      studentID
      gradeID
      grade {
        id
        grades
        comment
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      student {
        id
        name
        student_number
        email
        group
        other_info
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAssignment = /* GraphQL */ `
  mutation DeleteAssignment(
    $input: DeleteAssignmentInput!
    $condition: ModelAssignmentConditionInput
  ) {
    deleteAssignment(input: $input, condition: $condition) {
      id
      file_url
      img_url
      assignment_type
      studentID
      gradeID
      grade {
        id
        grades
        comment
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      student {
        id
        name
        student_number
        email
        group
        other_info
        createdAt
        updatedAt
      }
    }
  }
`;
export const createGrade = /* GraphQL */ `
  mutation CreateGrade(
    $input: CreateGradeInput!
    $condition: ModelGradeConditionInput
  ) {
    createGrade(input: $input, condition: $condition) {
      id
      grades
      comment
      createdAt
      updatedAt
    }
  }
`;
export const updateGrade = /* GraphQL */ `
  mutation UpdateGrade(
    $input: UpdateGradeInput!
    $condition: ModelGradeConditionInput
  ) {
    updateGrade(input: $input, condition: $condition) {
      id
      grades
      comment
      createdAt
      updatedAt
    }
  }
`;
export const deleteGrade = /* GraphQL */ `
  mutation DeleteGrade(
    $input: DeleteGradeInput!
    $condition: ModelGradeConditionInput
  ) {
    deleteGrade(input: $input, condition: $condition) {
      id
      grades
      comment
      createdAt
      updatedAt
    }
  }
`;
export const createTA = /* GraphQL */ `
  mutation CreateTA($input: CreateTAInput!, $condition: ModelTAConditionInput) {
    createTA(input: $input, condition: $condition) {
      id
      email
      username
      group
      createdAt
      updatedAt
    }
  }
`;
export const updateTA = /* GraphQL */ `
  mutation UpdateTA($input: UpdateTAInput!, $condition: ModelTAConditionInput) {
    updateTA(input: $input, condition: $condition) {
      id
      email
      username
      group
      createdAt
      updatedAt
    }
  }
`;
export const deleteTA = /* GraphQL */ `
  mutation DeleteTA($input: DeleteTAInput!, $condition: ModelTAConditionInput) {
    deleteTA(input: $input, condition: $condition) {
      id
      email
      username
      group
      createdAt
      updatedAt
    }
  }
`;
