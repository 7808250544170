import './MainSection.css';

import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AuthContext } from '../Contexts';
import GradeProjects from './main/GradeProjects';
import Landing from './main/Landing';
import NotFound from './main/NotFound';
import User from './main/User';
import ViewProjects from './main/ViewProjects';

// import GuestSearchesList from './main/GuestSearchesList';
// import GuestSearchResult from './main/GuestSearchResult';

// import Explorations from './main/UserSearchesList';
// import JobForm from './main/UserCreateNewSearch';
// import JobResults from './main/UserSearchResult';
function PrivateRoute({ component: Component, ...rest}) {
  const { cognitoPayload, isLoading } = useContext(AuthContext);
  return (
    !isLoading ?
    <Route 
      {...rest}
      render={
        props => cognitoPayload ? <Component {...props}/> : <Redirect to={{ pathname:"/", state: { from: props.location } }} />
      }
    />
    : null
  )
}

function MainSection() {
  return (
    <section id="main-section">
      <Switch>
        <Route path="/" exact >
            <Redirect to="/view#assignment=2" />
        </Route>
        <Route path="/signin" component={ Landing }/>
        <Route path="/view" component={ ViewProjects }/>
        {/* <Route path="/view-searches" exact component={ GuestSearchesList }/>
        <Route path="/view-searches/search-results" exact component={ GuestSearchResult }/> */}
        <PrivateRoute path="/user" component={ User }/>
        {/* <PrivateRoute path="/new-job" component={ JobForm }/>
        <PrivateRoute path="/searches/search-results" component={ JobResults }/> */}
        <PrivateRoute path="/grade" component={ GradeProjects }/>
        <Route path="/404" component={ NotFound }/>
        <Redirect to="/404"/>
      </Switch>
    </section>
  );
}

export default MainSection;
